import {
  Button,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
//import { useAddToHomescreenPrompt } from './useAddToHomescreenPrompt';
import chromeIcon from './downloadChrome.png';
import safariIcon from './downloadSafari.png';
import * as storage from '../../utils/storage';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    borderRadius: '25px',
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  link: {
    cursor: 'pointer',
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: '1rem 0px 0.25rem',
  },
  h4: {
    minWidth: '65%',
  },
  h5: {
    fontSize: '0.8rem',
  },
  dialogContent: {
    padding: '0 1.8rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  infoText: {
    margin: '0 auto 0.5rem',
    '& > *': {
      margin: '1rem auto',
    },
    '& li p': {
      color: '#263228',
    },
    '& li': {
      padding: '0 0 0 10px ',
    },
    '& ul': {
      margin: '0',
      padding: '0',
    },
  },
  icon: {
    minWidth: ' 35px',
  },
  beforeIcon: {
    marginBottom: '5px',
  },
  actionButton: {
    textAlign: 'center',
    margin: '1rem 0',
  },
  outlined: {
    border: '2px solid black',
  },
}));

export default function AddToHomescreenPrompt({ homeScreenModalClose }: any) {
  const classes = useStyles();
  const [isVisible, setVisibleState] = useState(true);

  //const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  // Detects if device is on iOS
  // const isIos = () => {
  //   const userAgent = window.navigator.userAgent.toLowerCase();
  //   return /iphone|ipad|ipod/.test(userAgent);
  // };
  // // Detects if device is in standalone mode
  // const isInStandaloneMode = (navigator: any) =>
  //   'standalone' in navigator && navigator.standalone;

  React.useEffect(() => {
    // if (prompt) {
    //   setVisibleState(true);
    // }

    if (storage.get('PWA_POPUP_CLOSE')) {
      setVisibleState(false);
    }

    // if (isIos() && !isInStandaloneMode(window.navigator)) {
    //   setVisibleState(true);
    // }
  }, []); //prompt

  if (!isVisible) {
    return <div className="invisible" />;
  }

  const handleModalClose = () => {
    storage.set('PWA_POPUP_CLOSE', true);
    setVisibleState(false);

    homeScreenModalClose();
  };

  return (
    <Dialog
      onClose={handleModalClose}
      aria-labelledby="customized-dialog-title"
      open={isVisible}
      PaperProps={{
        style: {
          width: '343px',
          overflowX: 'hidden',
          height: 'auto',
          borderRadius: '20px',
          paddingBottom: '20px',
        },
      }}
    >
      <div className={classes.dialogPaper}>
        <div className={classes.dialogHeader}>
          <Typography variant="h4" className={classes.h4}>
            Get the HoverIT app!
          </Typography>
          <IconButton style={{ color: '#000000' }} onClick={handleModalClose}>
            <ClearIcon />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <div className={classes.infoText}>
            <Typography variant="body2">
              There is nothing to download in the app store! Get the HoverIT app
              by following these simple steps!
            </Typography>
            <Typography variant="subtitle2" className={classes.beforeIcon}>
              1 - Tap the share icon on the menu of your browser
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <img
                    src={safariIcon.src}
                    alt="Safari Icon"
                    width="20px"
                    height="20px"
                  />
                </ListItemIcon>
                <ListItemText secondary="For Iphone on Safari" />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <img
                    src={chromeIcon.src}
                    alt="Chrome Icon"
                    width="20px"
                    height="20px"
                  />
                </ListItemIcon>
                <ListItemText secondary="For Android on Chrome" />
              </ListItem>
            </List>
            <Typography variant="subtitle2">
              2 - Select &quot;Add to Home Screen&quot;
            </Typography>
            <Typography variant="subtitle2">
              3- Label it as &quot;HoverIT&quot;
            </Typography>
          </div>
          <div>
            {/* <Grid item xs={12} className={classes.actionButton}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="button"
                onClick={promptToInstall}
              >
                Install HoverIT app
              </Button>
            </Grid> */}
            <Grid item xs={12} className={classes.actionButton}>
              <Button
                fullWidth
                variant="outlined"
                type="button"
                className={classes.outlined}
                onClick={handleModalClose}
              >
                I got it!
              </Button>
            </Grid>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
