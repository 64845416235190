import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Brightness1, NotificationsNoneOutlined } from '@mui/icons-material';
import { useSubscription } from '@apollo/client';
import { useRouter } from 'next/router';
import { NEW_NOTIFICATION_ADDED } from '../../../graphQL/subscriptions/notification';

const useStyles = makeStyles(() => ({
  dot: {
    color: '#F26B1D',
    fontSize: '0.60rem',
    position: 'absolute',
    top: '6px',
    right: '7px',
  },
}));

interface NotificationNavProps {
  isLoggedIn: Boolean;
}

const NotificationNav = ({ isLoggedIn }: NotificationNavProps) => {
  const classes = useStyles();
  const router = useRouter();

  const { data, loading } = useSubscription(NEW_NOTIFICATION_ADDED);

  const goToNotification = () => {
    if (isLoggedIn) {
      router.push('/notifications');
    } else {
      router.push('/login');
    }
  };

  return (
    <IconButton
      color="default"
      onClick={goToNotification}
      aria-label="Notifications"
      title="Notifications"
    >
      <NotificationsNoneOutlined />
      {!loading && data?.notificationCreated && (
        <Brightness1 className={classes.dot} />
      )}
    </IconButton>
  );
};

export default NotificationNav;
