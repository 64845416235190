import gql from 'graphql-tag';

export const NEW_NOTIFICATION_ADDED = gql`
  subscription {
    notificationCreated {
      id
      content
      newNotification
      created_at
      store {
        id
        business_name
        profile_image
      }
    }
  }
`;
