import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Chip, FormHelperText } from '@mui/material';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SEARCH_INTERESTS_LIST } from '../../graphQL/queries/tag';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface InterestModalProps {
  userInterestIds: number[];
  question?: string;
  saveErrorMsg?: string;
  // eslint-disable-next-line no-unused-vars
  onSave: (interestIds) => void;
}

export const InterestModal = ({
  question = 'Whats are you interested in?',
  saveErrorMsg = '',
  userInterestIds,
  onSave,
}: InterestModalProps) => {
  const [error, setError] = useState<string>('');
  const [interestIds, setInterestIds] = useState<number[]>([]);

  const { data: interests } = useQuery(SEARCH_INTERESTS_LIST, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const storageInterestIds = localStorage.getItem('interestIds');
    let selectedInterestIds = [];

    if (storageInterestIds) {
      selectedInterestIds = JSON.parse(storageInterestIds);
    }
    if (userInterestIds && userInterestIds.length > 0) {
      selectedInterestIds = [...selectedInterestIds, ...userInterestIds];
    }
    setInterestIds(selectedInterestIds);
  }, [userInterestIds]);

  const onClickInterest = (interestId: number) => {
    if (interestIds.includes(interestId)) {
      interestIds.splice(interestIds.indexOf(interestId), 1);
      setInterestIds([...interestIds]);
    } else {
      setInterestIds([...interestIds, interestId]);
    }
  };

  const onSaveProfile = () => {
    if (saveErrorMsg && interestIds.length > 5) {
      setError(saveErrorMsg);
      return;
    }

    onSave(
      interestIds.map((interestId) => {
        return {
          id: interestId,
          name: interests.interests.filter(
            (interest) => interest.id == interestId,
          )[0].name,
          type: 'interest',
        };
      }),
    );
  };

  return (
    <div>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Typography style={{ margin: 24 }} variant="h2">
              {question}
            </Typography>
            <div>
              {interests?.interests.length > 0 &&
                interests?.interests.map((interest) => {
                  return (
                    <Chip
                      style={{ margin: 4 }}
                      color={
                        interestIds.includes(interest.id)
                          ? 'success'
                          : 'default'
                      }
                      key={interest.id}
                      label={interest.name}
                      onClick={() => {
                        onClickInterest(interest.id);
                      }}
                    />
                  );
                })}
              {error && <FormHelperText error>{error}</FormHelperText>}
            </div>

            <Button
              fullWidth
              style={{ margin: 4, marginTop: 24 }}
              variant="contained"
              onClick={onSaveProfile}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
