import gql from 'graphql-tag';

export const CHAT_ROOM_CREATED_SUBSCRIPTION = gql`
  subscription {
    chatRoomCreated {
      id
      username
      profile_image
      message
      has_owner_seen
    }
  }
`;

export const NEW_MESSAGE_ADDED = gql`
  subscription newMessageAdded($chatRoomId: ID!) {
    newMessageAdded(chatRoomId: $chatRoomId) {
      id
      type
      message
      is_seen
      is_owner
    }
  }
`;

export const NEW_MESSAGE_NOTIFICATION = gql`
  subscription newMessageNotification {
    newMessageNotification {
      is_seen
    }
  }
`;

export const MESSAGE_TYPING_TRACKING = gql`
  subscription messageTypingTracking($chatRoomId: ID!) {
    messageTypingTracking(chatRoomId: $chatRoomId) {
      chat_room_id
      message
      full_name
    }
  }
`;
