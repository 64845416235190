import {
  AppBar,
  Container,
  IconButton,
  Snackbar,
  Toolbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  HomeOutlined,
  SearchOutlined,
  AccountCircleOutlined,
  AddToQueue,
} from '@mui/icons-material';
import { useRouter } from 'next/router';
import useMiddlewares from '../../../frontend/core/useMiddleware';
import { useState } from 'react';
import MessageNav from './BootomNav/MessageNav';
import NotificationNav from './BootomNav/NotificationNav';
import SideNav from './SideNav';

const useStyles = makeStyles(() => ({
  appBar: {
    top: 'auto',
    position: 'fixed',
    bottom: -1,
    backgroundColor: '#fff',
    paddingBottom: '10px',
    zIndex: 9,
    display: 'none',

    '@media (max-width: 1130px)': {
      display: 'block',
    },
  },
  navItemFooter: {
    justifyContent: 'space-between',
  },
}));

const BottomNav = () => {
  const classes = useStyles();
  const router = useRouter();
  const { isLoggedIn, isVendor, user, isOrganisation } = useMiddlewares();
  const [feature, setFeature] = useState(false);

  const goToAccount = () => {
    if (isLoggedIn) {
      router.push('/account');
    } else {
      router.push('/login');
    }
  };

  const goToSearch = () => {
    router.push('/search');
  };

  const goToPosts = () => {
    if (isLoggedIn) {
      if (isOrganisation) {
        router.push('/organisation/posts/create');
      } else {
        router.push('/posts/create');
      }
    } else {
      router.push('/login');
    }
  };
  const goToHome = () => {
    if (isVendor) {
      router.push('/vendor');
    } else if (isOrganisation) {
      router.push('/vendor');
    } else {
      router.push('/');
    }
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setFeature(false);
  };
  return (
    <>
      <SideNav
        isVendor={isVendor}
        interestIds={user?.me?.interestIds}
        isLoggedIn={isLoggedIn}
      />
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          autoHideDuration={1000}
          open={feature}
          resumeHideDuration={0}
          onClose={handleCloseSnackbar}
          message="Feature coming soon.."
        />
        <Container maxWidth="sm">
          <Toolbar className={classes.navItemFooter}>
            <IconButton
              color="default"
              onClick={goToHome}
              aria-label="Home"
              title="Home"
            >
              <HomeOutlined />
            </IconButton>
            {isVendor || isOrganisation ? (
              <IconButton
                color="default"
                onClick={goToPosts}
                aria-label="New post"
                title="New post"
              >
                <AddToQueue />
              </IconButton>
            ) : (
              <IconButton
                color="default"
                onClick={goToSearch}
                aria-label="Search"
                title="Search"
              >
                <SearchOutlined />
              </IconButton>
            )}
            <MessageNav isLoggedIn={isLoggedIn} />

            <NotificationNav isLoggedIn={isLoggedIn} />
            <IconButton
              color="default"
              onClick={goToAccount}
              aria-label="Account"
              title="Account"
            >
              <AccountCircleOutlined />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default BottomNav;
