import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TextsmsOutlined, Brightness1 } from '@mui/icons-material';
import { useSubscription, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { NEW_MESSAGE_NOTIFICATION } from '../../../graphQL/subscriptions/message';
import { GET_CHAT_ROOM_NOTIFICATION } from '../../../graphQL/queries/message';

const useStyles = makeStyles(() => ({
  dot: {
    color: '#F26B1D',
    fontSize: '0.60rem',
    position: 'absolute',
    top: '6px',
    right: '7px',
  },
}));

interface MessageNavProps {
  isLoggedIn: Boolean;
}

const MessageNav = ({ isLoggedIn }: MessageNavProps) => {
  const classes = useStyles();
  const router = useRouter();

  const { data, loading } = useSubscription(NEW_MESSAGE_NOTIFICATION);

  const { data: notification } = useQuery(GET_CHAT_ROOM_NOTIFICATION, {
    fetchPolicy: 'no-cache',
  });

  const goToMessage = () => {
    if (isLoggedIn) {
      router.push('/messages');
    } else {
      router.push('/login');
    }
  };

  return (
    <IconButton
      color="default"
      onClick={goToMessage}
      aria-label="Messages"
      title="Messages"
    >
      <TextsmsOutlined />

      {(loading || !data?.newMessageNotification) &&
        notification?.getChatRoomNotification?.is_seen && (
          <Brightness1 className={classes.dot} />
        )}

      {!loading && data?.newMessageNotification?.is_seen && (
        <Brightness1 className={classes.dot} />
      )}
    </IconButton>
  );
};

export default MessageNav;
