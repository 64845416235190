import gql from 'graphql-tag';

export const SEARCH_STORE_OR_USER_BY_HANDLER = gql`
  query ($handler: String) {
    searchStoreOrUserByHandler(handler: $handler) {
      id
      username
      chat_room_id
      profile_image
    }
  }
`;

export const GET_CHAT_ROOM_BY_ID = gql`
  query ($id: ID!) {
    getChatRoomById(id: $id) {
      id
      username
    }
  }
`;

export const GET_CHAT_ROOMS = gql`
  query ($search: String) {
    chatRooms(search: $search) {
      id
      username
      profile_image
      message
      has_owner_seen
    }
  }
`;

export const GET_CHAT_ROOM_NOTIFICATION = gql`
  query {
    getChatRoomNotification {
      is_seen
    }
  }
`;

export const MESSAGES_BY_CHAT_ROOM_ID = gql`
  query ($chatRoomId: ID!) {
    messagesByChatRoomId(chatRoomId: $chatRoomId) {
      id
      type
      message
      is_owner
    }
  }
`;
